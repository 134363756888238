<template>
  <NuxtLayout :name="layout">
    <RenderCacheable class="error" :max-age="216000" :cache-tags="['error' + statusCode]">
      <div class="error__illustration">
        <img src="~assets-brand/error/generic-error.svg" />
      </div>
      <div class="error__content">
        <h2 class="error__title" v-text="$t(errrorTitle)" />
        <div class="error__description">
          <p v-text="$t(errrorText)" />
        </div>
        <idf-button v-if="canReturnToHome" kind="primary" tag="a" href="/">{{ $t('error.button') }}</idf-button>
      </div>
    </RenderCacheable>
  </NuxtLayout>
</template>

<script lang="ts">
import { computed, ref, defineComponent, useNuxtApp, useHead, useRoute } from '#imports'
import { IdfButton } from '@invivodf/ui-kit'
import { useMetaHead } from '@application/composables/metas'

const STATUS_CODE = {
  SITE_EN_MAINTENANCE: 580
}

export default defineComponent({
  name: 'error',
  components: {
    IdfButton
  },
  props: {
    error: {
      type: Object
    }
  },
  setup(props) {
    const { $i18n: i18n } = useNuxtApp()
    const route = useRoute()
    const layout = ref('default')
    const canReturnToHome = ref(true)
    const title = ref(i18n.t('error.title') as string)
    const { metaInfo } = useMetaHead({ title })

    const statusCode = computed(() => props?.error?.statusCode)

    const errrorTitle = computed(() => {
      switch (props?.error?.statusCode) {
        case 580:
          return 'error.580.title'
        default:
          return 'error.title'
      }
    })

    const errrorText = computed(() => {
      switch (props?.error?.statusCode) {
        case 404:
          return 'error.404'
        case 503:
          return 'error.503'
        case 580:
          return 'error.580.description'
        default:
          return 'error.generic'
      }
    })

    if (statusCode.value === STATUS_CODE.SITE_EN_MAINTENANCE) {
      layout.value = 'no-layout'
      canReturnToHome.value = false
    }

    useHead(() => metaInfo.value)

    return { route, errrorText, metaInfo, statusCode, layout, canReturnToHome, errrorTitle }
  }
})
</script>

<style lang="scss" scoped>
@import '@invivodf/ui-kit/dist/css/mixins';

.error {
  display: flex;
  flex-direction: column;
  padding: 0 var(--idf-spacing-3) var(--idf-spacing-8);
  color: var(--idf-color-neutral-2-700);
  text-align: center;

  @include idf-media-up('md') {
    flex-direction: row;
    align-items: center;
    max-width: 80rem;
    margin: 0 auto;
    text-align: left;
  }

  &__illustration {
    margin-bottom: var(--idf-spacing-3);

    img {
      max-height: 26rem;
    }

    @include idf-media-up('md') {
      margin-right: var(--idf-spacing-3);
      margin-bottom: 0;
      padding: 0 8rem;

      img {
        max-width: 26rem;
        max-height: inherit;
        margin: 0 auto;
      }
    }
  }

  &__content {
    @include idf-font('l');

    @include idf-media-up('md') {
      max-width: 36rem;
    }
  }

  &__title {
    margin-bottom: var(--idf-spacing-4);
    font-weight: bold;
    white-space: pre-line;
  }

  &__description {
    margin-bottom: var(--idf-spacing-4);
  }
}
</style>
